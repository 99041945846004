import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/Layout"
import PageTemplate from "@shockventures/gatsby-theme-common/src/templates/PageTemplate"
import siteContext from "../assets/siteContext"

export const query = graphql`
    query ($id: String) {
        page: sanityPage(id: {eq: $id}) {
            ...BasePage
        }
    }
`
export default (props) => {
  return (
      <PageTemplate {...props} siteContext={siteContext}>
        <Layout siteContext={siteContext} data={props.data}/>
      </PageTemplate>
  )
}
